import React, { useCallback, useEffect, useRef } from 'react';
import unified from 'unified'; // @ts-ignore no types for this lib

import remarkParse from 'remark-parse'; // @ts-ignore no types for this lib

import remark2react from 'remark-react'; // @ts-ignore no types for this lib

import remarkDisableTokenizers from 'remark-disable-tokenizers';
import CSMarkdownLink from './CSMarkdownLink';
import { Routes } from '../nav/constants';
import { AssetType } from '@commonstock/common/src/types';
import CSMarkdownImage from './CSMarkdownImage';
import { createElement, ReactNode } from 'react';
import emojiRegex from 'emoji-regex';
import { AssetMention, LinkPreview, MentionAttachments, ProfileMention, TweetPreview } from '@commonstock/common/src/types/mentions';
import { css, cx } from '@linaria/core';
import { getMarkdownFromCopiedText, getTextNodesIn } from './utils';
import { captureException } from '../../dev/sentry';
import { HTMLDivProps } from '../../utils/types';
import { baseTextStyle, emojiClass } from '../../components/styles';
import { linkClass } from '../../theme/AtomicClasses';
import useMediaQuery from '@commonstock/common/src/utils/useMediaQuery';
import { SmallMobileBreakpoint } from 'src/theme/constants';
import { ROOT_FONT_SIZE, ROOT_LINE_HEIGHT, ROOT_MOBILE_FONT_SIZE } from 'src/theme/Types';
import { useFlags } from 'src/scopes/feature-flags/useFlags';
import useIsomorphicLayoutEffect from 'src/utils/useIsomorphicLayoutEffect';
import { useModal } from '../modal/Modal';
import { useInView } from 'react-intersection-observer';
const DEFAULT_LINE_HEIGHT = ROOT_LINE_HEIGHT * parseFloat(ROOT_FONT_SIZE);
const DEFAULT_SMALL_LINE_HEIGHT = ROOT_LINE_HEIGHT * parseFloat(ROOT_MOBILE_FONT_SIZE); // @TODO figure out a different strategy that avoids needing to add className here

const Div = (props: HTMLDivProps) => createElement('div', {
  className: contentBlock,
  ...props
});

const remark2reactOptions = {
  remarkReactComponents: {
    a: CSMarkdownLink,
    img: CSMarkdownImage,
    // Fix a copy/paste that was creating new more line breaks than expected
    p: Div
  }
}; // List of tokens https://github.com/remarkjs/remark/tree/8fad30e203d033a23965dd7d49c882d1795fb12c/packages/remark-parse#parserblocktokenizers

const blockTokens = ['paragraph', // Every line is a paragraph, even list items have paragraphs around texts, so it always need to be there
'thematicBreak', // This is HR element, need to add this to make lists work, probably a remark problem ¯\_(ツ)_/¯
'list', // All kind of lists
'newline', // This is for soft breaks, we dont use it, because we break the text to get all line breaks
'indentedCode', 'fencedCode', 'blockquote', 'atxHeading', 'setextHeading', 'html', 'footnote', 'definition', 'table'];
const inlineTokens = ['text', // This represents the textNode, so its need to be always there
'url', // this makes plain text links work
'link', // this parses links and images, (mentions are links, cant parse mentions without parsing images)
'strong', // bold text node
'emphasis', // italic text node
'escape', 'autoLink', 'html', 'reference', 'deletion', 'code', 'break', 'image', 'img'];
export const regexEmoji = emojiRegex();
export const bioMarkdown = unified().use(remarkParse).use(remarkDisableTokenizers, {
  block: blockTokens.filter(b => !['paragraph'].includes(b)),
  inline: inlineTokens.filter(b => !['url', 'text'].includes(b))
}).use(remark2react, remark2reactOptions);
export const commentsMarkdown = unified().use(remarkParse).use(remarkDisableTokenizers, {
  block: blockTokens.filter(b => !['thematicBreak', 'list', 'paragraph'].includes(b)),
  inline: inlineTokens.filter(b => !['url', 'link', 'text', 'strong', 'emphasis'].includes(b))
}).use(remark2react, remark2reactOptions);
export const postsMarkdown = unified().use(remarkParse).use(remarkDisableTokenizers, {
  block: blockTokens.filter(b => !['paragraph'].includes(b)),
  inline: inlineTokens.filter(b => !['link', 'text'].includes(b))
}).use(remark2react, remark2reactOptions);
export const chatMarkdown = unified().use(remarkParse).use(remarkDisableTokenizers, {
  block: blockTokens.filter(b => !['paragraph'].includes(b)),
  inline: inlineTokens.filter(b => !['link', 'text'].includes(b))
}).use(remark2react, remark2reactOptions);
export const memoMarkdown = unified().use(remarkParse).use(remarkDisableTokenizers, {
  block: blockTokens.filter(b => !['thematicBreak', 'list', 'paragraph'].includes(b)),
  inline: inlineTokens.filter(b => !['url', 'link', 'text', 'strong', 'emphasis'].includes(b))
}).use(remark2react, remark2reactOptions);

function isListMarkdownEnabled(processor: unified.Processor<unified.Settings> = memoMarkdown) {
  return processor === memoMarkdown;
}

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function addProtocol(href: any) {
  const shouldAddProtocol = typeof href === 'string' && !/^[/@]/gi.test(href) && !/^(?:f|ht)tps?:\/\//.test(href);
  return shouldAddProtocol ? 'http://' + href : href;
}
const bulletListItemRegex = new RegExp(/^- .*/);
const numberedListItemRegex = new RegExp(/^(\d+)\. .*/);
export const isMarkdownParseableRegex = new RegExp(/\*.*\*|_.*_|\[.*\]\(.*\)|^\d+|^- |http|www|\*.*/);

function getLinkRanges(text: string) {
  return (Array.from(text.matchAll(/\[.*?\]\(.*?\)/gm)).map(m => m?.index !== undefined && [m.index, m.index + m[0].length]).filter(Boolean) as Array<[number, number]>);
}

type FormatterProps = {
  text?: string;
  mentions?: MentionAttachments;
  processor?: unified.Processor<unified.Settings>;
  clampLine?: number;
  showSeeMore?: boolean;
  className?: string;
  removeLinebreaks?: boolean;
  removeLinks?: boolean;
};
export function Formatter({
  text,
  mentions,
  processor = memoMarkdown,
  clampLine,
  showSeeMore,
  className,
  removeLinebreaks,
  removeLinks
}: FormatterProps) {
  if (!text) return null; // Remove empty line links

  text = text.replace(/\[\n\]\(.*?\)/g, '\n') || '';
  let linkRanges = getLinkRanges(text);
  const assetMentions: {
    [key: string]: AssetMention;
  } = {};
  mentions?.asset_mentions?.forEach(m => assetMentions[`${m.symbol}:${m.type}`] = m); // rehydrate user and asset mentions
  // @TODO ideally these would already be links, but need to strip link for now to accomodate iOS
  // @NOTE we filter out empty attachments just in case of BE bug

  Object.values(assetMentions)?.filter(m => m.symbol).forEach(am => {
    let key = am.symbol + (am.type === AssetType.crypto ? '.X' : '');
    let keyRe = new RegExp(`[$]${escapeRegExp(key)}${am.type === AssetType.equity ? '(?!\\.[xX])' : ''}(\\b)`, 'g');
    const assetMatches = Array.from((text || '').matchAll(keyRe)).reverse(); // Check if mention is inside links to avoid double linking

    assetMatches.forEach(m => {
      if (text === undefined || m.index === undefined || linkRanges.filter(range => m.index && m.index > range[0] && m.index < range[1]).length) return;
      text = `${text.substr(0, m.index)}[$${key}](${Routes.asset(am?.symbol, am?.type)})${text.substr(m.index + m[0].length)}`;
      linkRanges = getLinkRanges(text);
    });
  }); // Need to renew this range list after each mention

  linkRanges = getLinkRanges(text);
  const profileMentions: {
    [key: string]: ProfileMention;
  } = {};
  mentions?.user_mentions?.forEach(m => profileMentions[`${m.username}`] = m);
  Object.values(profileMentions)?.filter(m => m.username).forEach(um => {
    let keyRe = new RegExp(`[@]${escapeRegExp(um.username)}(\\b)`, 'g'); // text = text?.replace(keyRe, `[@${um.username}](${Routes.usernameProfile(um?.username)})`)

    const profileMatches = Array.from((text || '').matchAll(keyRe)).reverse(); // Check if mention is inside links to avoid double linking

    profileMatches.forEach(m => {
      if (text === undefined || m.index === undefined || linkRanges.some(range => m.index && m.index > range[0] && m.index < range[1])) return;
      text = `${text.substr(0, m.index)}[@${um.username}](${Routes.usernameProfile(um?.username)})${text.substr(m.index + m[0].length)}`;
    });
  }); // This regex will remove all markdown links.

  if (removeLinks) {
    text = text.replace(/(?:__|[*#])|\[(.*?)\]\(.*?\)/gm, '$1');
  }

  const links: {
    [key: string]: LinkPreview | TweetPreview;
  } = {};
  const {
    tweets = [],
    link_previews = []
  } = mentions || {};
  const linkMentions = [...tweets, ...link_previews];
  linkMentions.forEach(m => links[`${m.original_text}`] = m);
  Object.values(linkMentions)?.filter(m => m.original_text).forEach(m => {
    let keyRe = new RegExp(`${escapeRegExp(m.original_text)}`, 'gi');
    const urlLink = m.url;
    let urlText = 'display_url' in m ? m.display_url : m.original_text;
    urlText = urlText === urlLink ? urlLink : urlText;
    text = removeLinks ? text?.replace(keyRe, `${urlLink}`) : text?.replace(keyRe, `[${urlText}](${addProtocol(urlLink)})`);
  });
  return <FormattedReactText text={text} processor={processor} clampLine={clampLine} showSeeMore={showSeeMore} className={className} removeLinebreaks={removeLinebreaks} />;
}

function FormattedReactText({
  text,
  processor = memoMarkdown,
  clampLine,
  showSeeMore,
  className,
  removeLinebreaks
}: {
  text: string;
  processor: unified.Processor<unified.Settings>;
  clampLine?: number;
  showSeeMore?: boolean;
  className?: string;
  removeLinebreaks?: boolean;
}) {
  const {
    activeModal
  } = useModal();
  let [inViewRef, inView] = useInView({
    threshold: 1
  });
  const {
    webEmojiToImage
  } = useFlags();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const bodyRef = useRef<HTMLDivElement | null>(null);
  const setWrapperRefs = useCallback((node: HTMLDivElement) => {
    wrapperRef.current = node;
    inViewRef(node);
  }, [inViewRef]);
  const isSmallMobile = useMediaQuery(`screen and (max-width: ${SmallMobileBreakpoint})`);
  const defaultLineHeight = isSmallMobile ? DEFAULT_SMALL_LINE_HEIGHT : DEFAULT_LINE_HEIGHT;
  const getWrapperMaxHeight = useCallback(() => {
    if (!clampLine) return 'unset';
    const lineHeight = bodyRef.current && parseFloat(window.getComputedStyle(bodyRef.current).lineHeight) || defaultLineHeight;
    return lineHeight * clampLine;
  }, [clampLine, defaultLineHeight]);
  useEffect(() => {
    requestIdleCallback(() => webEmojiToImage && bodyRef.current && wrapEmojiCharacters(bodyRef.current), {
      timeout: 500
    });
  }, [webEmojiToImage]);
  useIsomorphicLayoutEffect(() => {
    bodyRef.current?.classList.remove('clamped');
  }, [isSmallMobile]);
  useIsomorphicLayoutEffect(() => {
    if (!clampLine || !bodyRef.current) return;
    if (wrapperRef.current) wrapperRef.current.style.setProperty('max-height', `${getWrapperMaxHeight()}px`);
    if (!inView && !bodyRef.current?.classList.contains('clamped')) return;
    requestAnimationFrame(() => bodyRef.current && clampLastValidItem(bodyRef.current, clampLine, !!showSeeMore));
  }, [isSmallMobile, getWrapperMaxHeight, clampLine, showSeeMore, inView, activeModal]); // Remark, ignores empty lines, thats why we split the text and force add the line breaks
  // Since we are spliting line breaks it create separate lists for each list item, and this broke how ordered list items work

  let textParts = text.split(/[\u2028\u2029\n]/);
  textParts = !isListMarkdownEnabled(processor) ? textParts : textParts // We reverse the text parts to be able to identify numbered sequences easily
  .reverse().reduce((acc: string[], t: string) => {
    // Removes trailing space/line breaks e.g.(text↵↵↵)
    if (!acc.length && t === '') return acc;
    let lastItem = acc.length > 0 && acc.slice(-1)[0] || '';
    let isBulletListItem = t.match(bulletListItemRegex);
    let isInsideBulletList = lastItem.match(bulletListItemRegex); // We join subsequent list items on one single text part

    if (isBulletListItem && isInsideBulletList) return [...acc.slice(0, -1), `${t}\n${lastItem}`];
    let isNumberedListItem = t.match(numberedListItemRegex);
    let isInsideNumberedList = lastItem.match(numberedListItemRegex);

    if (isNumberedListItem && isInsideNumberedList) {
      let isSubsequentNumber = Number(isNumberedListItem[1]) + 1 === Number(isInsideNumberedList[1]); // We join subsequent list items on one single text part, but only if the numbers are in a sequence row

      if (isSubsequentNumber) return [...acc.slice(0, -1), `${t}\n${lastItem}`];
    }

    return [...acc, t];
  }, []).reverse();
  const onCopy = useCallback((e: React.ClipboardEvent<HTMLDivElement>) => {
    getMarkdownFromCopiedText(e);
    e.preventDefault();
  }, []); // Removing line breaks for top memo cards

  if (removeLinebreaks) {
    textParts = textParts.filter(t => {
      if (!t.trim() || t === '\\') return false;
      return true;
    });
  }

  const body = <div key={text} ref={bodyRef} className={cx(baseTextStyle, className)} onCopy={onCopy}>
      {textParts.map((t, i) => !t.trim() || t === '\\' ? // @TODO allow for blank lines infront for reposts
    <>{i === 0 ? <Div key={i}> </Div> : <br key={i} />}</> : isMarkdownParseableRegex.test(t) ? <React.Fragment key={i}>{(processor.processSync(t).result as ReactNode)}</React.Fragment> : <Div key={i}>{t}</Div>)}
    </div>;

  if (clampLine) {
    // Limit parts and size to improve performance lists (e.g.: Feed)
    textParts = textParts.slice(0, clampLine + 1); // This first div only exists to limit text max size and avoid repaiting after clamping

    return <div ref={setWrapperRefs} style={{
      maxHeight: `${getWrapperMaxHeight()}px`,
      overflow: 'hidden'
    }}>
        {body}
      </div>;
  }

  return body;
}

type CaretPosition = {
  offset: number;
  offsetNode: Node;
}; // Find the last visible item to be show, and set the clamp to it
// This is needed because Safari (also W3C docs) describe that clamping to be applied to current element text

export function clampLastValidItem(parent: HTMLDivElement, maxLines: number, hasSeeMore: boolean) {
  // @NOTE: default value based on paragraph lineheight on memos
  // @NOTE: using parseFloat, as it can get float numbers as 18.75 on mobile
  const lineHeight = parseFloat(window.getComputedStyle(parent).lineHeight) || DEFAULT_LINE_HEIGHT; // @NOTE: based on initial max clamp size

  let maxHeight = maxLines * lineHeight;
  if (parent.clientHeight <= maxHeight) return null;
  const selection = document.getSelection();
  if (!selection) return;
  const currentRange = selection.rangeCount && selection.getRangeAt(0);
  const preSelectionRange = currentRange && currentRange.cloneRange();
  const parentBox = parent.getBoundingClientRect(); // Estimated space of ellipsis + see more text

  const neededSpace = 16 + (hasSeeMore ? 70 : 0);
  let range: Range | null = null;
  const xPos = parentBox.right - neededSpace;
  const yPos = parentBox.top + maxHeight - 4;

  try {
    if (document.caretRangeFromPoint) {
      range = document.caretRangeFromPoint(xPos, yPos);
    } else if ('caretPositionFromPoint' in document) {
      // @ts-ignore only available on FF
      const positionNode: CaretPosition | null = document.caretPositionFromPoint(xPos, yPos);
      if (!positionNode) return;
      range = new Range();
      range.setStart(positionNode?.offsetNode, positionNode?.offset);
    }
  } catch (error) {
    captureException(error);
    return;
  }

  if (!range) return;
  if (!parent.contains(range.startContainer)) return;
  range.setEndAfter(parent);
  selection.removeAllRanges();
  selection.addRange(range);
  selection.deleteFromDocument();
  let children: Element[] = Array.from(parent.children).flatMap(c => ['UL', 'OL'].includes(c.tagName) ? Array.from(c.children) : c);
  let reversedChildren = children.reverse();

  for (const el of reversedChildren) {
    // @ts-ignore
    const text = (el.innerText || '').trim();

    if (!text) {
      el.remove(); // Update maxHeight based on parent, since are removing an item

      maxHeight = parent.clientHeight;
      continue;
    }

    const ellipsis = document.createElement('SPAN');
    ellipsis.innerText = '… ';
    el.insertAdjacentElement('beforeend', ellipsis);

    if (hasSeeMore) {
      const seeMore = document.createElement('SPAN');
      seeMore.innerText = 'See More';
      seeMore.classList.add(linkClass);
      el.insertAdjacentElement('beforeend', seeMore);
    }

    break;
  }

  selection.removeAllRanges();
  preSelectionRange && selection.addRange(preSelectionRange);
  parent.classList.add('clamped');
}
export function wrapEmojiCharacters(target: HTMLElement) {
  const nodes = getTextNodesIn(target);

  for (let node of nodes) {
    const text = node.textContent || '';
    const match = text.match(regexEmoji);

    if (match) {
      let innerHTML = text;
      let regex = emojiRegex();
      const emojis = text.match(regex);

      if (emojis) {
        let emojisSet = new Set(Array.from(emojis));

        for (let emoji of emojisSet) {
          const span = document.createElement('span');
          const image = getEmojiImageElement(emoji, node.parentElement ? window.getComputedStyle(node.parentElement).fontSize : '24px');
          span.appendChild(image);
          innerHTML = innerHTML.replace(new RegExp(`${emoji}`, 'g'), span.innerHTML);
        }
      }

      const wrapper = document.createElement('span');
      wrapper.innerHTML = innerHTML;
      node.replaceWith(...Array.from(wrapper.childNodes));
    }
  }
}
export function getEmojiImageElement(emoji: string, textSize?: string) {
  const size = textSize && parseInt(textSize) || 24;
  const canvas = document.createElement('canvas');
  const multiplier = 1;
  canvas.width = size * 2 * multiplier;
  canvas.height = size * 2 * multiplier;
  const ctx = canvas.getContext('2d');
  if (!ctx) return document.createTextNode(emoji);

  try {
    // The size of the emoji is set with the font
    ctx.font = `${size * multiplier}px serif`; // use these alignment properties for "better" positioning

    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle'; // draw the emoji

    ctx.fillText(emoji, canvas.width * 0.5, canvas.height * 0.5);
    const {
      image: imageUrl,
      w,
      h
    } = cropImageFromCanvas(ctx);
    const node = document.createElement('img');
    node.setAttribute('src', imageUrl);
    node.setAttribute('alt', emoji); // This adds size to emoji imgs, while fixing max size proportions

    node.setAttribute('width', `${size / Math.max(size, w, h) * w}`);
    node.setAttribute('height', `${size / Math.max(size, w, h) * h}`);
    node.classList.add(emojiClass);
    return node;
  } catch (error) {
    captureException(error);
    return document.createTextNode(emoji);
  }
} // @NOTE: Crop emoji image to vsible part of it
// https://stackoverflow.com/a/22267731/4009378

function cropImageFromCanvas(ctx: CanvasRenderingContext2D) {
  let canvas = ctx.canvas,
      w = canvas.width,
      h = canvas.height,
      pix: {
    x: number[];
    y: number[];
  } = {
    x: [],
    y: []
  },
      imageData = ctx.getImageData(0, 0, canvas.width, canvas.height),
      x,
      y,
      index;

  for (y = 0; y < h; y++) {
    for (x = 0; x < w; x++) {
      index = (y * w + x) * 4;

      if (imageData.data[index + 3] > 0) {
        pix.x.push(x);
        pix.y.push(y);
      }
    }
  }

  pix.x.sort(function (a, b) {
    return a - b;
  });
  pix.y.sort(function (a, b) {
    return a - b;
  });
  let n = pix.x.length - 1;
  w = 1 + pix.x[n] - pix.x[0];
  h = 1 + pix.y[n] - pix.y[0];
  let cut = ctx.getImageData(pix.x[0], pix.y[0], w, h);
  canvas.width = w;
  canvas.height = h;
  ctx.putImageData(cut, 0, 0);
  let image = canvas.toDataURL('image/png', 1); //open cropped image in a new window

  return {
    image,
    w,
    h
  };
}

const contentBlock = "cv4onxa";

require("../../../.linaria-cache/packages/oxcart/src/scopes/text-editor/csMarkdown.linaria.module.css");